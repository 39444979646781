import React, { FC, useState, useEffect } from 'react';
import Script from 'next/script';
import ReferralCookie from '../../libs/referralCookie';
import Skeleton from './Skeleton';
import { useSeriesContext } from '../../context/SeriesContext';
import { useUserProfileContext } from '../../context/UserProfileContext';

interface GlobalAuthProps {
  type: 'WP-Registration' | 'WP-Login' | 'ResetPassword';
  redirectUrl?: string;
  hostUrl: string;
  loadingType?: string;
  redirect?: boolean;
  passwordLess?: boolean;
  borderless?: boolean;
  channelId?: string;
}

const _GlobalAuth: FC<GlobalAuthProps> = ({
  type = 'WP-Registration',
  redirectUrl = null,
  hostUrl,
  loadingType = 'bt-login-form',
  redirect = true,
  passwordLess = false,
  borderless = false,
  channelId,
}) => {
  const [isLoading, setLoading] = useState(true);

  const getReferralCookie = ReferralCookie(hostUrl, redirectUrl);
  const [storedReferralCookie, setReferralCookie] = useState<
    string | undefined
  >();

  const { setModal, setTriggerReg } = useSeriesContext();
  const { setUserProfile } = useUserProfileContext();

  useEffect(() => {
    setReferralCookie(getReferralCookie);

    const smartformComponent = document.querySelector(
      'globalauth-helpercomponent'
    );
    if (smartformComponent) {
      smartformComponent.addEventListener('globalauthMessage', handleEvent);
    }
  }, [getReferralCookie]);

  const handleEvent = (event) => {
    if (event?.detail?.type === 'globalauth-mounted') {
      setLoading(false);
    }
    if (
      event?.detail?.type === 'login-success' ||
      event?.detail?.type === 'registration-success' ||
      event?.detail?.type === 'passwordless-login-success'
    ) {
      setModal(false);
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        isLoggedIn: true,
      }));
      setTriggerReg(true);
    }
  };

  return (
    <>
      <Script
        src={
          hostUrl +
          `/globalauth-helpercomponent/globalauth-helpercomponent.esm.js`
        }
        type="module"
      />

      {isLoading && <Skeleton formType={loadingType} />}

      <globalauth-helpercomponent
        form-style='{"primaryColor": "#003CB2", "secondaryColor": "#003CB2"}'
        comp-type={type}
        language="en-US"
        referral-cookie-name={redirect ? storedReferralCookie : ''}
        envvalue={process.env.BASE_ENV}
        passwordlessflow={passwordLess}
        borderless={borderless}
        channelid={channelId}
        tabindex={-1}
      />
    </>
  );
};

export default _GlobalAuth;
