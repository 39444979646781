import React from 'react';
import classnames from 'classnames';
import styles from './skeleton.module.scss';

const Skeleton = ({formType}) => {
    return (
        <>
            <div className={classnames( formType === 'WP-Registration' ? styles['bt-login-form'] : styles['bt-modal-form'], styles['skeleton-loading'])}>
                <div className={styles['form-heading']}></div>
                <h4 className={styles['form-sub-heading']}></h4>
                <div className={styles['form-input']}></div>
                { formType === 'WP-Registration' ? (
                    <>
                    <div className={styles['form-input']}></div>
                    <div className={styles['join-form-button']}></div>
                    <div className={styles['terms-prompt']}>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                    </>
                ) : (
                    <div className={styles['login-form-button']}></div>
                )}
            </div>
        </>
    );
};

export default Skeleton;